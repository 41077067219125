<template>
    <!--商品计划交易列表-->
    <div>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input v-model.trim="form.search" placeholder="计划名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item of depts"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态">
                            <el-select v-model="form.status">
                                <el-option label="请选择" value="" />
                                <el-option label="待生成" value="2" />
                                <el-option label="已生成" value="1" />
                                <el-option label="无需生成" value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                size="small"
                type="primary"
                @click="searchHandleQuery"
                v-if="hasPrivilege('menu.plan.goods.sell.open')"
                >查询
            </el-button>
            <el-button
                size="small"
                type="primary"
                @click="createReceiveStockBiz"
                :disabled="disableCreateReceiveStockBizBtn"
                v-if="hasPrivilege('menu.plan.goods.sell.stock')"
            >
                内购出库
            </el-button>
            <el-button
                size="small"
                type="primary"
                @click="handleExport"
                v-if="hasPrivilege('menu.plan.goods.sell.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="计划名称"
                    width="160"
                    prop="merchandisingName"
                    v-if="showColumn('merchandisingName')"
                />
                <el-table-column label="商品名称" width="160" prop="goodsName" v-if="showColumn('goodsName')" />
                <el-table-column label="交易流水号" width="160" prop="no" v-if="showColumn('no')" />
                <el-table-column label="数量" width="160" prop="count" v-if="showColumn('count')" />
                <el-table-column
                    label="业务员"
                    width="160"
                    prop="orderReceiverName"
                    v-if="showColumn('orderReceiverName')"
                />
                <el-table-column label="领用单编码" width="160" prop="receiveCode" v-if="showColumn('receiveCode')" />
                <el-table-column
                    label="状态"
                    width="160"
                    prop="receiveStatus"
                    v-if="showColumn('receiveStatus')"
                    key="receiveStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.receiveStatus | settleStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="会员姓名" width="160" prop="memberName" v-if="showColumn('memberName')" />
                <el-table-column label="会员电话" width="160" prop="mobile" v-if="showColumn('mobile')" />
                <el-table-column label="完成时间" width="140" prop="finishTime" v-if="showColumn('finishTime')" />
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import EfEndDate from 'components/EfEndDate';
import EfStartDate from 'components/EfStartDate';
import Util from 'js/Util';
import EfPagination from 'components/EfPagination';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'GoodsPlanSell',
    mixins: [CheckTableShowColumn],
    components: { EfStartDate, EfEndDate, EfPagination, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                search: '',
                deptCode: '',
                status: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],
            selectedBizArr: [],
            depts: [],
        };
    },
    filters: {
        settleStatus(receiveStatus) {
            if (typeof receiveStatus == 'undefined') {
                return '';
            }
            if (receiveStatus == '1') {
                return '已生成';
            }
            if (receiveStatus == '2') {
                return '待生成';
            }
        },
    },
    mounted() {
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.depts = rst;
        });
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {
        disableCreateReceiveStockBizBtn() {
            return this.selectedBizArr.length === 0;
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            this.$efApi.goodsPlanSellApi.page(this.form).then((rst) => {
                this.tableData = rst.data;
                this.total = rst.count;
            });
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        async createReceiveStockBiz() {
            await this.$efApi.goodsPlanSellApi.createReceiveStockBiz(this.selectedBizArr.map((e) => e.code));
            this.$message.success('内购出库成功');
            this.handleQuery();
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(
                this,
                '商品计划交易列表',
                `${this.$efApi.goodsPlanSellApi.baseUrl}/export`,
                this.form,
                codes
            );
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
    },
};
</script>
